import React from 'react';
import { graphql, Link } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';

import SEO from './seo';
import SiteLayout from './layout';

const PostLayout = ({ data: { mdx }, pageContext: { next, previous } }) => {
  return (
    <SiteLayout>
      <SEO title={mdx.frontmatter.title} description={mdx.excerpt} />
      <article>
        <header>
          <h1>{mdx.frontmatter.title}</h1>
          <small className="flex-between">
            <p>{mdx.frontmatter.date}</p>
            <p>{mdx.timeToRead} minute read</p>
          </small>
        </header>
        <MDXRenderer>{mdx.body}</MDXRenderer>
      </article>
      <footer style={{ marginTop: '32px', marginBottom: '32px' }}>
        {previous ? (
          <>
            Previous:{' '}
            <Link to={previous.fields.slug}>{previous.frontmatter.title}</Link>
            <br />
          </>
        ) : (
          ''
        )}
        {next ? (
          <>
            Next: <Link to={next.fields.slug}>{next.frontmatter.title}</Link>
          </>
        ) : (
          ''
        )}
      </footer>
    </SiteLayout>
  );
};

export default PostLayout;

export const pageQuery = graphql`
  query BlogPostQuery($id: String) {
    mdx(id: { eq: $id }) {
      id
      body
      timeToRead
      excerpt
      frontmatter {
        title
        date(formatString: "DD MMMM, YYYY")
      }
    }
  }
`;
